import {useEffect, useState} from "react";
import AmogeServer from "@unclejust/amoge-client/src/utils/AmogeServer";
import * as dayjs from "dayjs";
import {PromotionConfig} from "./admin/promo/PromotionUpload";
import useWindowDimensions from "@unclejust/amoge-client/src/utils/misc-hooks";
import ImagicianSources from "@unclejust/amoge-client/src/components/ImagicianSources";

export default function PromotionPresenter() {

   const [config, setConfig] = useState<PromotionConfig>();
   const [imageLoaded, setImageLoaded] = useState<boolean>(false);
   const [showing, setShowing] = useState<boolean>(false);
   const [promotionPresenterCss, setPromotionPresenterCss] = useState("promotion-presenter");
   const {width, height} = useWindowDimensions();

   const isPortrait = () => {
      return width < height;
   }

   const imageName = () => {
      return isPortrait() ? config?.portraitImageName : config?.landscapeImageName;
   }

   const promotionVisible = () => {
      return imageName() && showing;
   }

   const orientationCss = () => {
      return isPortrait() ? "portrait" : "landscape";
   }

   useEffect(() => {
      AmogeServer<PromotionConfig>("/api/promotions/config", null, false)
      .then(response => {
         setConfig(response);
      })
   }, [])

   useEffect(() => {
      if (config) {
         const from = dayjs(config.from);
         const to = dayjs(config.to);
         const active = config.active;
         const now = dayjs();
         setShowing(active && !from.isAfter(now) && !to.isBefore(now))
      }
   }, [config])

   useEffect(() => {
      if (promotionVisible() && imageLoaded) {
         const t = setTimeout(() => {
            setPromotionPresenterCss("promotion-presenter viewing");
         }, 250)
         return () => clearTimeout(t);
      }
   }, [promotionVisible(), imageLoaded])

   const close = event => {
      event.preventDefault();
      setPromotionPresenterCss("promotion-presenter closing");
      setTimeout(() => {
         setShowing(false);
      }, 500)
   }

   function onImageLoad() {
      setImageLoaded(true);
   }

   function orientedPromotionCss() {
      return "promotion " + orientationCss();
   }

   return <>
      {promotionVisible() && <div className={promotionPresenterCss}>
         <div className={orientedPromotionCss()}>
            <a className="book-now-link" href={"/book-a-course.html"}>
               <picture>
                  <ImagicianSources galleryName="promotions" imageName={`/${imageName()}`}/>
                  <img src={`/api/promotions/image${imageName()}`}
                       alt="Promotion" onLoad={onImageLoad}/>
               </picture>
            </a>
         </div>
         <div className="close-button">
            <a href="" onClick={close}>Close</a>
         </div>
      </div>}
   </>

}