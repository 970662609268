import SlideshowComponent from "@unclejust/amoge-client/src/components/Slideshow";

export type SlideshowProps = {
   id: string,
   configEditorUri?: string,
   galleryName?: string
}

export default function Slideshow(props: SlideshowProps) {
   return <SlideshowComponent id={props.id}
                              configEditorUri={props.configEditorUri || '/coby/admin/slideshow-editor.html'}
                              galleryName={props.galleryName || "ltb"}/>
}