import {useState, useRef, useEffect, SetStateAction, MutableRefObject, Dispatch} from "react";

export const useStateWithRef = <T>(initialValue?: T): [T, Dispatch<SetStateAction<T>>, MutableRefObject<T>] => {
   const [state, setState] = useState<T>(initialValue);
   const stateRef = useRef<T>(initialValue);
   useEffect(() => {
      stateRef.current = state;
   }, [state])
   return [state, setState, stateRef];
}

export const useEffectAfterFirst = (fn, deps) => {
   const isFirst = useRef(true);
   useEffect(() => {
      if (isFirst) {
         isFirst.current = false;
      } else {
         return fn();
      }
   }, deps)
}

function getWindowDimensions() {
   const { innerWidth: width, innerHeight: height } = window;
   return {
      width,
      height
   };
}

export default function useWindowDimensions() {
   const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

   useEffect(() => {
      function handleResize() {
         setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
   }, []);

   return windowDimensions;
}

